import React from "react"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import HeroLegals from "@components/Hero/HeroLegals"
import LegalsContent from "@components/LegalsContent"
import { graphql } from "gatsby"

function TermsTemplate(props) {
  const { internalName, content } = props?.data?.pageData

  return (
    <Layout>
      <Seo
        title="Workgrid Software Terms and Conditions"
        description="Workgrid is a digital workplace platform that simplifies the work day and improves employee experience."
        metaTags="workgrid, digital workplace, enterprise software"
      />
      <HeroLegals title={internalName} />
      <LegalsContent content={content} />
    </Layout>
  )
}

export default TermsTemplate

export const PageQuery = graphql`
  query TermsPageQuery {
    pageData: contentfulComponentGeneric(
      internalName: { eq: "Terms and Conditions" }
    ) {
      id
      internalName
      content: bodyCopy {
        raw
        references {
          id
          link
          label
        }
      }
    }
  }
`
